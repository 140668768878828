var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a", {
    staticClass: "mail",
    attrs: {
      href: _vm.getLinkAddress,
      target: "_blank",
      title: "E-Mail mit Link zum Domizil verschicken",
      "data-toggle": "tooltip"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }