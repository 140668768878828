<template>
    <span id="contentStart"></span>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'scroll-to-div',

    	mounted: function() {
    		$('html, body').animate({
    			scrollTop: $("#contentStart").offset().top
    		}, 2000);
    	},
    };
</script>