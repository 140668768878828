<template>
    <a :href="getLinkAddress"
        target="_blank"
        class="mail"
        title="E-Mail mit Link zum Domizil verschicken"
        data-toggle="tooltip">

    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'mail-link',
    	props: {
    		unitname: String,
    		unittype: String,
    		unitpath: String,
    		fromunitpage: String
    	},
    	computed: {
    		getLinkAddress: function() {
    			var linkText = 'mailto:?subject=';
    			if (this.unittype) {
    				linkText += this.unittype + " ";
    			}
    			if (this.unitname) {
    				linkText += this.unitname;
    				linkText += "&body=" + this.unitname;
    			}
    			if (this.fromunitpage == 'true') {
    				linkText += " " + window.location.href;
    			} else if (this.unitpath) {
    				linkText += " " + window.location.protocol + "//" + window.location.hostname + "/" + this.unitpath;
    			}
    			return linkText;
    		}
    	}
    };
</script>