var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fullstars > 0
    ? _c(
        "div",
        {
          staticClass: "ratings",
          attrs: { "data-toggle": "tooltip", title: _vm.getTitle }
        },
        _vm._l(_vm.fullstars, function(n, index) {
          return _c("i", { staticClass: "ic-logo blogo" })
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }