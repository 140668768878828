<template>
    <div v-if="type!='button'"
        class="fav-element"
        @click.prevent="toggleFav"
        data-toggle="tooltip"
        :title="title">

        <i class="fa"
            aria-hidden="true"
            :class="{'fa-heart':isFav(),'fa-heart-o':!isFav()}"></i>

    </div>

    <button v-else
        type="button"
        @click="toggleFav">
        <i class="fa"
            aria-hidden="true"
            :class="{'fa-heart':isFav(),'fa-heart-o':!isFav()}"></i>

        <span v-show="!isFav()">{{addLabel}}</span>
        <span v-show="isFav()">{{removeLabel}}</span>

    </button>



</template>

<script>
    import V from 'voUtils/V.js'

    import {
    	isFav, toggleFav, onFavUpdate, getFavIds
    }
    from 'voUtils/FavStore.js';


    export default {
    	voVueComponent: 'voffice-fav-element',
    	props: {
    		type: String,
    		unitId: Number,
    		addLabel: String,
    		removeLabel: String
    	},
    	computed: {
    		title: function() {
    			return this.voMsg('favorites');
    		}
    	},
    	mounted: function() {
    		onFavUpdate(() => {
    			this.$forceUpdate();
    		});

    	},
    	methods: {

    		isFav: function() {
    			return isFav(this.unitId);
    		},
    		toggleFav: function() {
    			V.log("Fav clicked!", this.unitId);
    			toggleFav(this.unitId);
    		}
    	}


    };
</script>