var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.type != "button"
    ? _c(
        "div",
        {
          staticClass: "fav-element",
          attrs: { "data-toggle": "tooltip", title: _vm.title },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.toggleFav($event)
            }
          }
        },
        [
          _c("i", {
            staticClass: "fa",
            class: { "fa-heart": _vm.isFav(), "fa-heart-o": !_vm.isFav() },
            attrs: { "aria-hidden": "true" }
          })
        ]
      )
    : _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.toggleFav } },
        [
          _c("i", {
            staticClass: "fa",
            class: { "fa-heart": _vm.isFav(), "fa-heart-o": !_vm.isFav() },
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFav(),
                  expression: "!isFav()"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.addLabel))]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFav(),
                  expression: "isFav()"
                }
              ]
            },
            [_vm._v(_vm._s(_vm.removeLabel))]
          )
        ]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }