<template>
    <div class="ratings"
        v-if="fullstars > 0"
        data-toggle="tooltip"
        :title="getTitle">
        <i v-for="(n, index) in fullstars"
            class="ic-logo blogo"></i>
    </div>
</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'rating-display',
    	props: {
    		rating: Number
    	},

    	computed: {
    		fullstars: function() {
    			console.log('this.rating' + this.rating);
    			if (this.rating >= 0) {
    				return parseInt(this.rating) + 1;
    			} else {
    				return 0;
    			}
    		},
    		getTitle: function() {
    			if (this.rating == 0) {
    				return 'Klassik';
    			} else if (this.rating == 1) {
    				return 'Komfort';
    			} else if (this.rating == 2) {
    				return 'Premium';
    			}

    		}

    	}
    };
</script>